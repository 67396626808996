import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";

import * as Range from "ewoq/prototypes/Ranges";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_DRE_1",
    mandatory: true,
    title: <span>Did you dream?</span>,
    plainTitle: "Did you dream?",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes" },
          { text: "No" },
          { text: "Not sure" },
          { text: "Yes, but I don't remember anything" }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_2",
    mandatory: false,
    plainTitle: "Dream description:",
    title: (
      <span>
        Please describe your dreams in as much detail as you can remember:
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Input.Question onValid={v} large={true} inputType="string" />
    )
  },
  {
    id: "SR_DRE_5.1",
    mandatory: false,
    plainTitle: "Emotion level of dream:",
    title: <span>In general, how emotional was the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely neutral", "Extremely emotional"]}
      />
    )
  },
  {
    id: "SR_DRE_5.2",
    mandatory: false,
    plainTitle: "Emotions in dream:",
    title: <span>What was the emotional tone during the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { question: "Happy", labels: ["Not at all", "Dominant"] },
          { question: "Fearful" },
          { question: "Angry" },
          { question: "Frustrated" },
          { question: "Embarrassed" },
          { question: "Confused" },
          { question: "Comforting" },
          { question: "Afraid/Desparate" },
          { question: "Disgusted" },
          { question: "Sad/Sorrowful", labels: ["Not at all", "Dominant"] },
          { question: "Depressed" },
          { question: "Hollow/empty" },
          { question: "Tense/Stressed" },
          { question: "Worried" },
          { question: "Exciting" },
          { question: "Funny/amused" },
          { question: "Bored" },
          { question: "Lonely" },
          { question: "Busy" }
        ]}
      />
    )
  },
  {
    id: "SR_DRE_5.3",
    mandatory: false,
    plainTitle: "Other emotions:",
    title: "Were other emotions involved?",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] > 0.25;
    },
    component: v => (
      <Input.Question onValid={v} inputType="string" extraRadio={["None"]} />
    )
  },
  {
    id: "SR_DRE_6",
    mandatory: false,
    plainTitle: "Senses involved:",
    title: <span>Which senses were involved?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Visual" },
          { text: "Auditory" },
          { text: "Olfactory (smell)" },
          { text: "Taste" },
          { text: "Balance" },
          { text: "Other", extraInput: "string" }
        ]}
        allAbove
      />
    )
  },
  {
    id: "SR_DRE_7.1",
    mandatory: false,
    plainTitle: "Dream vividness:",
    title: <span>How vivid was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely hazy", "Extremely vivid"]}
      />
    )
  },
  {
    id: "SR_DRE_7.2",
    mandatory: false,
    plainTitle: "Dream realism:",
    title: <span>How realistic was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely crazy", "Extremely realistic"]}
      />
    )
  },
  {
    id: "SR_DRE_7.3",
    mandatory: false,
    plainTitle: "Dream complexity:",
    title: <span>How complex was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Simple", "Extremely complex"]}
      />
    )
  },
  {
    id: "SR_DRE_7.4",
    mandatory: false,
    plainTitle: "Dream pleasantness:",
    title: <span>How pleasant overall was your dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Nightmare", "Wonderful"]}
      />
    )
  },
  {
    id: "SR_DRE_8.1",
    mandatory: false,
    plainTitle: "Dreamer's movement:",
    title: <span>How much did you move your body in the dream?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely still", "Moving all the time"]}
      />
    )
  },
  {
    id: "SR_DRE_8.2",
    mandatory: false,
    plainTitle: "Other movement in dream:",
    title: <span>How much did other things move?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Completely still", "Movement all the time"]}
      />
    )
  },
  {
    id: "SR_DRE_8.3",
    mandatory: false,
    plainTitle: "Travelling in dream:",
    title: <span>How much did you travel from place to place?</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_DRE_1");
      return a[idx].data !== undefined && a[idx].data["answer"] === 0;
    },
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Stayed in one place", "Always travelling"]}
      />
    )
  },

  {
    id: "SR_SLE_1.1",
    mandatory: true,
    plainTitle: "Sleep quality:",
    title: <span>How was your sleep?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Terrible", "Standard", "Perfect"] },
          { labels: ["Superficial", "Deep"] },
          { labels: ["Fragmented", "Continuous"] },
          { labels: ["Restless", "Calm"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_1.2",
    mandatory: true,
    plainTitle: "Current state: ",
    title: <span>How do you feel right now?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Tired", "Refreshed"] },
          { labels: ["In a bad mood", "In a good mood"] },
          { labels: ["Apathetic", "Full of energy"] },
          { labels: ["Restless / tense", "Calm"] },
          { labels: ["Unfocused", "Concentrated"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_2",
    mandatory: true,
    title: <span>How was your sleep environment?</span>,
    plainTitle: "Sleep environment:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Comfortable", "Uncomfortable"] },
          { labels: ["Too quiet", "Perfect", "Too noisy"] },
          { labels: ["Too cold", "Perfect", "Too warm"] },
          { labels: ["Too dark", "Perfect", "Too bright"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_3.1",
    title: <span>Did anything disturb your sleep?</span>,
    plainTitle: "Sleep disturbances:",
    explanation: (
      <span>Did you notice something unusual (smells, sounds, etc)?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. people talking"
        extraRadio={["No", "Maybe, but I don't know what"]}
      />
    )
  },

  {
    id: "SR_SLE_3.2",
    title: "When during the night were you disturbed?",
    plainTitle: "Disturbance timing:",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_2");
      return a[idx].data !== undefined && a[idx].data["radioAnswer"] !== 0;
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={["Beginning", "Middle", "End", "Unsure"]}
      />
    )
  },
  {
    id: "SR_SLE_4",
    mandatory: true,
    title: <span>How long did it take you to fall asleep, approximately?</span>,
    plainTitle: "Time to fall asleep:",
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="Minutes" />
    )
  },
  {
    id: "SR_SLE_5.1",
    mandatory: true,
    title: <span>How easy was it for you to fall asleep?</span>,
    plainTitle: "Ease of falling asleep:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely hard", "Extremely easy"]}
      />
    )
  },
  {
    id: "SR_SLE_5.2",
    mandatory: false,
    plainTitle: "Causes for difficulty falling asleep:",
    title: (
      <span>
        Did any of the following make it difficult to fall asleep once you
        turned the lights off?
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] < 0.5;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          {
            text:
              "Ruminating thoughts (couldn’t stop thinking about something over and over)"
          },
          { text: "Anxiety" },
          { text: "Excitement" },
          { text: "Not optimal sleep environment" },
          { text: "Not tired enough" },
          { text: "Something, but I don't know what" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.1",
    mandatory: false,
    plainTitle: "Waking in the night:",
    title: <span>Did you wake up in the night?</span>,
    explanation:
      "This refers to waking up any time before you were supposed to, and had enough time to go back to sleep.",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Never" },
          { text: "Once or twice" },
          { text: "Several times" },
          { text: "I barely slept" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.2",
    mandatory: false,
    plainTitle: "Confidence in waking at night:",
    title: <span>How sure are you about waking up in the night?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["0%", "100% sure"]}
      />
    )
  },
  {
    id: "SR_SLE_7",
    mandatory: true,
    plainTitle: "Ease of waking up:",
    title: <span>How easy was it for you to wake up?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely easy", "Extremely hard"]}
      />
    )
  }
];

export default questions;
