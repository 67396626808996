import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as Slider from "ewoq/prototypes/Slider";
import WorldMap from "ewoq/prototypes/icons/worldmap.jpg";

import * as Map from "ewoq/prototypes/ImageCoordinates";

import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";

const questions: QuestionType[] = [
  {
    id: "LQ_DEM_1.1",
    mandatory: false,
    plainTitle: "Occupation",
    title: <span>Current occupation: </span>,
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. student, bartender"
      />
    )
  },
  {
    id: "LQ_DEM_1.2",
    mandatory: false,
    plainTitle: "Years of edicuation",
    title: <span>Indicate your current level of eduction:</span>,
    explanation: "Each label indicates that you have finished that school.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_2.1",
    mandatory: false,
    plainTitle: "father's job",
    title: "What is your father's occupation?",
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. banker, nurse"
        extraRadio={["I don't know", "Many different jobs", "Doesn't work"]}
      />
    )
  },
  {
    id: "LQ_DEM_2.2",
    mandatory: false,
    plainTitle: "father's education",
    title: <span>Indicate your father's level of education:</span>,
    explanation: "Each label indicates the last obtained degree.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_2.3",
    mandatory: false,
    plainTitle: "mother's job",
    title: "What is your mother's occupation?",
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. banker, nurse"
        extraRadio={["I don't know", "Many different jobs", "Doesn't work"]}
      />
    )
  },
  {
    id: "LQ_DEM_2.4",
    mandatory: false,
    plainTitle: "mother's education",
    title: <span>Indicate your mother's level of education:</span>,
    explanation: "Each label indicates the last obtained degree.",
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "None",
          "Elementary / Primary",
          "High school / gymnasium",
          "Bachelor's",
          "Masters",
          "Doctorate"
        ]}
        extraRadio={["Specialized training / apprenticeship"]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },

  {
    id: "LQ_DEM_7",
    mandatory: false,
    plainTitle: "Stigmatization",
    title: (
      <span>
        How stigmatized / socially marginalized do you personally feel in your
        daily life?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        labels={[
          "No stigma at all",
          "Constantly stigmatized / discriminated against"
        ]}
        sliderType={Slider.SliderType.horizontal}
      />
    )
  },
  {
    id: "LQ_DEM_9.1",
    mandatory: false,
    plainTitle: "Ethnic origin",
    title:
      "Please indicate on the map where you ethnically / genetically originate, to the best of your knowledge.",
    tip: "Click anywhere on the map",
    explanation:
      "This is to evaluate (approximately) if there are genetic differences in circadian rhythms or vulnerability to sleep deprivation.",
    component: v => <Map.Question onValid={v} src={WorldMap} />
  },
  {
    id: "LQ_DEM_9.2",
    mandatory: false,
    plainTitle: "Ethnic origin confidence",
    title: <span>How sure are you of your answer?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Random guess" },
          { text: "Good guess" },
          { text: "I know my family ancestry" },
          { text: "I took a genetic test" }
        ]}
      />
    )
  }, //TODO, make group question with previous

  {
    id: "LQ_DEM_11.2",
    mandatory: false,
    plainTitle: "Currently live with someone",
    title: <span>Do you currently live with a partner?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[{ text: "Yes" }, { text: "No" }, { text: "Sometimes" }]}
      />
    )
  }
];

export default questions;
