import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./SleepReportQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import SetIDSection from "Questionnaires/SetID";
import { getUserId } from "ewoq/utils";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

export class SleepReport extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("BIO", "SleepReport", {
      answers: answers
    });
    window.location.reload();
    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        <HeaderContainer>
          <Ui.Container textAlign="center">Sleep Report</Ui.Container>
        </HeaderContainer>
        <Ui.Container>
          <SetIDSection />
          {
            <Interrogator
              questions={questions}
              onDone={this.onSubmit}
              viewAll={this.props.viewAll}
            />
          }
        </Ui.Container>
      </div>
    );
  }
}

export default SleepReport;
