import React from "react";
import styled from "styled-components";

import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

import SleepReport from "./Questionnaires/SleepReport";

import Questionnaires from "./Questionnaires/";

import AllQs from "./Questionnaires/AllQuestionnaires";

import Queue from "ewoq/containers/Queue";
import Sleepiness from "Questionnaires/Sleepiness";
import Lifestyle from "Questionnaires/Lifestyle";

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomContainer = styled.div`
  background-color: #eee;
  min-height: 90vh;
  font-size: 100%;
  padding: 0.5em;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 100%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 90%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 70%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 45%;
  }
`;

const App: React.FC = () => (
  <BrowserRouter>
    <ParentContainer>
      <CustomContainer>
        <Switch>
          <Route exact path="/">
            <Questionnaires />
          </Route>
          <Route exact path="/questionnaires">
            <Questionnaires />
          </Route>
          <Route
            exact
            path="/screening"
            component={() => {
              window.location.href = "https://lsm.schlaflab.com/pilotscreening";
              return null;
            }}
          />

          <Route exact path="/sleepreport">
            <SleepReport />
          </Route>
          <Route exact path="/sleepiness">
            <Sleepiness />
          </Route>
          <Route exact path="/lifestyle">
            <Lifestyle />
          </Route>
          <Route exact path="/allqs">
            <AllQs />
          </Route>
          <Route exact path="/queue">
            <Queue />
          </Route>
        </Switch>
      </CustomContainer>
    </ParentContainer>
  </BrowserRouter>
);

export default App;
