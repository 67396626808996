import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Slider from "ewoq/prototypes/Slider";

import { kss } from "Questionnaires/StandardQuestionComponents/KSS";
import { fourEnergiesPresent } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import {
  feelings,
  extraFeelings
} from "Questionnaires/StandardQuestionComponents/Feelings";

const questions: QuestionType[] = [
  {
    id: "SLE_1",
    plainTitle: "KSS sleepiness:",
    title: <span>Please indicate your current sleepiness: </span>,
    component: v => kss(v)
  },
  {
    id: "SLE_3.1",
    plainTitle: "Alertness:",
    title: (
      <span>
        How alert are you? How quickly do you feel you would respond to
        something (e.g. a ball thrown at your head)?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Slow, sluggish", "Normal", "Hyperalert"]}
      />
    )
  },
  {
    id: "SLE_3.2",
    plainTitle: "Focus:",
    title: <span>How focused / concentrated are you at the moment?</span>,
    explanation:
      "Imagine you are in a lecture, how capable would you be of paying attention?",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Very unfocused", "Very concentrated"]}
      />
    )
  },
  {
    id: "SLE_4",
    plainTitle: "Sleep pressure:",
    title: <span>How much need do you feel to fall asleep right now?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I can't sleep right now" },
          { text: "I would have a hard time falling asleep" },
          { text: "I might sleep" },
          { text: "I would like to sleep" },
          { text: "I really want to sleep" },
          { text: "This is the most I've ever wanted to sleep" },
          { text: "I feel tired, but I don't think I could sleep" }
        ]}
      />
    )
  },
  {
    id: "SLE_5",
    mandatory: true,
    title: "Do you feel...",
    plainTitle: "Stress:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Relaxed", "Tense", "Stressed"]}
      />
    )
  }
];
export default questions;
