import React from "react";
import * as Ui from "semantic-ui-react";
import Handedness from "./Handedness";
import LifestyleSleep from "./LifestyleSleep";
import Demographics from "./Demographics";
import PainSensitivity from "./PainSensitivity";

import { getUserId } from "ewoq/utils";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  LinkQ
} from "ewoq/components/miscStyles";
import SetIDSection from "Questionnaires/SetID";

enum Phase {
  Start,
  Basics,
  Demographics,
  Handedness,
  Eating1,
  Eating2,
  Drinking,
  Lifestyle1,
  Lifestyle2,
  Health,
  LifestyleSleep,
  Disruption,
  Pain
}

interface Props {}
interface State {
  phase: Phase;
}

class Lifestyle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phase: Phase.Start
    };
  }

  advancePhase = (newPhase: Phase) => () => {
    this.setState({ phase: newPhase }, () => console.log(this.state));
  };

  render() {
    switch (this.state.phase) {
      case Phase.Start: {
        return (
          <div>
            <HeaderContainer>Lifestyle Questionnaire</HeaderContainer>
            <Ui.Container>
              <SetIDSection />
              <Ui.Segment>
                <Ui.Divider horizontal>Instructions</Ui.Divider>
                <SectionBody>
                  <p>
                    This questionnaire will ask you about yourself, lifestyle,
                    health, and sleep habits/quality. Questions that are not
                    mandatory will let you skip ahead if you don't feel
                    comfortable answering, or just don't want to, although we
                    appreciate every answer you give us. We recognize that some
                    questions are intrusive, and rather than risk a false
                    answer, we prefer if you skip them. You can always ask the
                    experimenter why we bother asking any question, and we will
                    be happy to explain. If you don’t understand the question,
                    please ask for clarification.
                  </p>
                </SectionBody>

                <SectionBody>
                  <Ui.Form>
                    <Ui.Form.Field>
                      <Ui.Button
                        fluid
                        onClick={this.advancePhase(Phase.LifestyleSleep)}
                      >
                        Start
                      </Ui.Button>
                    </Ui.Form.Field>
                  </Ui.Form>
                </SectionBody>
              </Ui.Segment>
            </Ui.Container>
          </div>
        );
      }
      case Phase.LifestyleSleep: {
        return <LifestyleSleep onDone={this.advancePhase(Phase.Handedness)} />;
      }
      case Phase.Handedness: {
        return <Handedness onDone={this.advancePhase(Phase.Demographics)} />;
      }

      case Phase.Demographics: {
        return <Demographics onDone={this.advancePhase(Phase.Pain)} />;
      }

      case Phase.Pain: {
        return <PainSensitivity onDone={this.advancePhase(Phase.Start)} />;
      }
    }
  }
}

export default Lifestyle;
