import React from "react";

import styled from "styled-components";
import * as Ui from "semantic-ui-react";

interface Props {
  sessions: string[][];
  selection?: string;
  title?: string;
  onClick: (selection: string) => void;
}

const SessionSegment = styled.div`
  margin-bottom: 1em;
`;

const ButtonBatch = styled.span`
  margin: 0.5em;
  padding: 0.5em;
  line-height: 3em;
`;

export class ChooseSession extends React.Component<Props> {
  render() {
    return (
      <Ui.Container textAlign="center">
        <SessionSegment>
          <Ui.Segment raised>
            <Ui.Divider horizontal>
              {this.props.title || "Choose session"}
            </Ui.Divider>
            <Ui.Form>
              <Ui.Form.Field>
                {this.props.sessions.map((sessionBatch, idx) => (
                  <ButtonBatch>
                    <Ui.Button.Group key={idx}>
                      {sessionBatch.map((session, idx) => (
                        <Ui.Button
                          onClick={() => this.props.onClick(session)}
                          key={idx}
                          color={
                            this.props.selection === session
                              ? "violet"
                              : undefined
                          }
                        >
                          {session}
                        </Ui.Button>
                      ))}
                    </Ui.Button.Group>
                  </ButtonBatch>
                ))}
              </Ui.Form.Field>
            </Ui.Form>
          </Ui.Segment>
        </SessionSegment>
      </Ui.Container>
    );
  }
}

export default ChooseSession;
