import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { getUserId, setUserId } from "ewoq/utils";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze
} from "ewoq/components/miscStyles";
import { obtainUserId, listQueue, ApiRequest } from "ewoq/api";

interface Props {
  timepoint?: boolean;
}
interface State {
  color: string;
}

const Center = styled.div`
  text-align: center;
  line-height: 3em;
`;

const SessionSegment = styled.div`
  margin-bottom: 1em;
`;

const Colors1 = ["red", "orange", "yellow"];
const Colors2 = ["olive", "green", "teal", "blue"];
const Colors3 = ["violet", "purple", "pink", "debug"];

export class SetIDSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const color = getUserId();
    this.state = {
      color: color || ""
    };
  }

  onClick = (color: string) => {
    this.setState({ color }, () => this.updateCookie());
  };
  updateCookie = () => {
    const unsaved = this.checkUnsaved();
    if (unsaved) {
      this.forceUpdate();
      return;
    } else {
      const words = this.state.color;

      const cookie = words;
      setUserId(cookie);
      this.forceUpdate();
    }
  };
  checkUnsaved = () => {
    const unsaved = !listQueue().every(k => {
      const data = JSON.parse(localStorage[k]) as ApiRequest;
      return data.isOnline;
    });
    return unsaved;
  };

  render() {
    return (
      <div>
        <Ui.Container textAlign="center">
          <SessionSegment>
            <Ui.Segment raised>
              <Ui.Divider horizontal>Choose ID</Ui.Divider>
              <Center>
                <Ui.Button.Group>
                  {Colors1.map((c, idx) => (
                    <Ui.Button
                      key={idx}
                      size="tiny"
                      color={c as any}
                      onClick={() => this.onClick(c)}
                      basic={c !== getUserId()}
                    >
                      {c}
                    </Ui.Button>
                  ))}
                </Ui.Button.Group>{" "}
                <Ui.Button.Group>
                  {Colors2.map((c, idx) => (
                    <Ui.Button
                      key={idx}
                      size="tiny"
                      color={c as any}
                      onClick={() => this.onClick(c)}
                      basic={c !== getUserId()}
                    >
                      {c}
                    </Ui.Button>
                  ))}
                </Ui.Button.Group>{" "}
                <Ui.Button.Group>
                  {Colors3.map((c, idx) => (
                    <Ui.Button
                      key={idx}
                      size="tiny"
                      color={c as any}
                      onClick={() => this.onClick(c)}
                      basic={c !== getUserId()}
                    >
                      {c}
                    </Ui.Button>
                  ))}
                </Ui.Button.Group>
              </Center>
            </Ui.Segment>
          </SessionSegment>
        </Ui.Container>
      </div>
    );
  }
}

export default SetIDSection;
