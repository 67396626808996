import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./SleepinessQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";
import ChooseSession from "ewoq/containers/DayPicker";
import SetIDSection from "Questionnaires/SetID";

interface Props {
  viewAll?: boolean;
  name?: string;
  onDone?: () => void;
}

interface State {
  name?: string;
}

export class Sleepiness extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { name: this.props.name || "" };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("BIO", this.state.name + "-Sleepiness", {
      answers: answers
    });
    window.location.reload();
    this.props.onDone && this.props.onDone();
  };

  onClick = (selection: string) => {
    this.setState({ name: selection });
  };

  render() {
    return (
      <div>
        <HeaderContainer>
          <Ui.Container textAlign="center">Sleepiness</Ui.Container>
        </HeaderContainer>
        <Ui.Container>
          <SetIDSection />

          <ChooseSession
            sessions={[["Time-1", "Time-2"], ["Time-3", "Time-4"], ["Redo"]]}
            selection={this.state.name}
            onClick={this.onClick}
          />
          {!(this.state.name && this.state.name.length > 0) ? null : (
            <Interrogator
              questions={questions}
              onDone={this.onSubmit}
              viewAll={this.props.viewAll}
            />
          )}
        </Ui.Container>
      </div>
    );
  }
}

export default Sleepiness;
