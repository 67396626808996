import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { getUserId, setUserId } from "ewoq/utils";

import {
  SectionBody,
  HeaderContainer,
  ParticipantID,
  Squeeze
} from "ewoq/components/miscStyles";
import { obtainUserId, listQueue, ApiRequest } from "ewoq/api";
import SetIDSection from "./SetID";

interface Props {}

interface State {
  color: string;
}

const LinkQ = styled.a`
  margin: 0.5em;
`;

const Center = styled.p`
  text-align: center;
`;

class Questionnaires extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">All Questionnaires</Ui.Container>
        </HeaderContainer>
        <Ui.Container>
          <Ui.Segment>
            <Ui.Header textAlign="center">New Questionnaire</Ui.Header>
            <Squeeze>
              <LinkQ
                href="https://lsm.schlaflab.com/pilotscreening"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="violet" fluid>
                  Screening Questionnaire (for experimenter)
                </Ui.Button>
              </LinkQ>

              <LinkQ
                href="/lifestyle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="violet" fluid>
                  Lifestyle Questionnaire (for experimenter)
                </Ui.Button>
              </LinkQ>

              <LinkQ
                href="/sleepreport"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Sleep Report
                </Ui.Button>
              </LinkQ>
              <LinkQ
                href="/sleepiness"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Ui.Button color="teal" fluid>
                  Sleepiness
                </Ui.Button>
              </LinkQ>
            </Squeeze>
          </Ui.Segment>
          <Ui.Segment>
            <Ui.Header textAlign="center">Questionnaires Submitted</Ui.Header>
            <Squeeze>
              {listQueue()
                .sort()
                .map((k, idx) => {
                  const data = JSON.parse(localStorage[k]) as ApiRequest;
                  return (
                    <Center key={idx}>
                      <Ui.Icon
                        color={data.isOnline ? "teal" : "grey"}
                        name="check"
                      />
                      {k}
                    </Center>
                  );
                })}
            </Squeeze>
            <p>
              If you don't see the latest questionniare, try refreshing the
              page.
            </p>
          </Ui.Segment>
        </Ui.Container>
      </div>
    );
  }
}

export default Questionnaires;
